.layout {
  height: 100%;
  padding-top: 47px;

  :global(.ant-layout-sider) {
    background: #fff;
  }
}

.standalone {
  padding-top: 0;
}

.layout-content-wrapper {
  height: 100%;

  // overflow: auto;
}

.is-mobile {
  padding-top: 0;
}
