.description {
  position: relative;
  line-height: 1.575;
}

.check {
  transition: all 0.2s ease-in-out !important;
  opacity: 0;
  display: none;
}

.copied {
  opacity: 1;
  display: block;
}

.close-icon:hover {
  background-color: #c3ecff;
  color: #0061c9;
}
