.drawerWarp {
  position: absolute;
  top: 0;
  height: 100vh;
  overflow: hidden;

  .drawerMask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
  }


}

.drawerContent {
  position: absolute !important;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  // transition: all 0.3s;
  .titleDrawer {
    padding: 16px;
    color: #000000d9;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid #f0f0f0;
  }

  .render {
    padding: 16px;
    height: calc(100% - 50px);
    overflow: auto;
  }

  .closeDrawerBtn {
    z-index: 1;
    position: absolute;
    top: 16px;
    right: 16px;
    padding-left: 16px;
    color: #ccc;
    font-size: 16px;
    cursor: pointer;
  }
}

.closeDrawer {
  visibility: hidden;
}