:global {
  .t-suspend-button {
    position: fixed;
    z-index: 999;
    top: 400px;
    right: 18px;
    border-radius: 2rem;

    // box-shadow: 0px 0px 5px rgba(#000000, 0.4);
  }

  .t-suspend-button img {
    border-radius: 50%;
  }

  .t-suspend-button-animate {
    transition-duration: 0.4s;
  }

  .bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
    color: #fff;
    font-size: 16px;
    overflow: hidden; /* 隐藏超出元素区域外的内容 */
    filter: blur(10px);
    background-color: rgb(255 255 255 / 72%);
  }

  .floatModal {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .floatModal p {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 0 0 -64px;
  }
}
