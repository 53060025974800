.bui-qiankun-micro-app {
  width: 100%;
  height: 100%;
  min-height: 100px;

  > div {
    height: 100%;
  }

  #canvas {
    overflow: hidden;
  }
}

.no-animation-drawer {
  :global {
    .ant-drawer-content-wrapper {
      transition: none !important;
    }

    .ant-drawer-content {
      transition: none !important;
    }
  }
}

.lcp-task-drawer {
  :global {
    .padding-container {
      padding: 0;
    }
  }
}

.qiankun-modal {
  :global {
    .pageBtnBar {
      position: sticky;
    }
  }
}

.qiankun-spin-container {
  :global {
    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
      }
    }
  }
}
