.close-all-notification {
  position: fixed;
  right: 31px;
  top: 60px;
  z-index: 1010;
  transition: all 0.2s ease-in-out;
  display: block !important;
  cursor: pointer;
  background-color: #0064ce !important;

  // color: #0064ce;
}
