.drag-line {
  height: 100%;
  width: 5px;
  background-color: #1a1a1a;
}

.lcp-page {
  padding: 0;
}

.not-lcp-page {
  padding: 20px;
}

.vertical-container {
  height: 100% !important;
}

.hori-container {
  width: 100% !important;
}
.padding-control {
  padding: 20px;
  height: 100%;
  width: 100%;
  position: relative;
}

.qiankun-spin-container {
  :global {
    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
      }
    }
  }
}
