.water-mark {
  position: fixed;
  top: -200px;
  left: -20px;
  width: 100%;
  height: 200%;
  z-index: 9;
  opacity: 0.05;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #000;
  pointer-events: none;
  transform: rotate(15deg);
  transform-origin: 50% 50%;

  > ul {
    > li {
      display: inline-block;
      width: 300px;
      margin-bottom: 170px;
      transform: rotate(-45deg);
      transform-origin: 50% 50%;
    }
  }
}
