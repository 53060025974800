.loading-circle {
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #136cf6;
  margin: 0 1px;
}

@keyframes grow {
  0%,
  50%,
  100% {
    transform: scale(0.5);
    background-color: #136cf6;
  }

  25% {
    transform: scale(1);
    background-color: #0038c9;
  }
}

.loading-animation1 {
  animation: 1.6s cubic-bezier(0.32, 0.06, 0.85, 1.11) 0s infinite normal none running grow;
}

.loading-animation2 {
  animation: 1.6s cubic-bezier(0.32, 0.06, 0.85, 1.11) 0.2s infinite normal none running grow;
}

.loading-animation3 {
  animation: 1.6s cubic-bezier(0.32, 0.06, 0.85, 1.11) 0.4s infinite normal none running grow;
}

.spin-indicator-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  line-height: 20px;
}
